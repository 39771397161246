.about-page__container {
  min-height: 100vh;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.about-side__pic__container {
  margin-left: 4vw;
  margin-right: 1vw;
  margin-top: 1vh;
  padding: 10px;
  min-width: calc(350px + (425 - 350) * ((100vw - 1000px) / (1800 - 1000)));

  display: flex;
  align-items: center;
  justify-content: center;
}

.about-side__pic {
  max-width: 100%;
  border-radius: 30px;
}

.about {
  font-family: ProximaNova;
  font-size: 1.4rem;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.about-text__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(202, 199, 199);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 2vw;
  margin-right: 4.5vw;
  line-height: calc(23px + (35 - 23) * ((100vw - 320px) / (1600 - 320)));

  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1600 - 320)));
}

.about-text__header {
  font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (1600 - 320)));
  line-height: calc(32px + (40 - 32) * ((100vw - 320px) / (1600 - 320)));

  color: white;
}

.about-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.45rem;
  line-height: 2.2rem;
  margin-right: 5rem;
  margin-left: 1rem;
  padding: 20px 0;
}

.about-text__paragraph {
  color: rgb(202, 199, 199);
}

.animate-character {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    rgb(252, 117, 68) 0%,
    #ff7300 29%,
    #fd2904 67%,
    orangered 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s reverse infinite;
  display: inline-block;
  font-size: inherit;
  /* font-size: calc(26px + (32 - 26) * ((100vw - 320px) / (1600 - 320))); */
  line-height: calc(30px + (35 - 30) * ((100vw - 320px) / (1600 - 320)));
  text-align: left;
}

.animate-character-ftx {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #00a7c3 0%,
    #00cadf 29%,
    #87ebf4 67%,
    #00cadf 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s reverse infinite;
  display: inline-block;
}

.scroll-container {
  overflow: hidden;
  height: 80vh;
}

.animator {
  height: 80vh;
  overflow: hidden;
}

.scroll-page {
  height: 80vh !important;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.above {
  text-decoration: underline;
}

/* @media screen and (max-width: 1440px) {
  .about-side__pic__container {
    min-width: 550px;
    height: 80vh;
  }
  .about-side__pic {
    height: 85%;
    width: 70%;
  }
  .about-text {
    margin-right: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .about-side__pic__container {
    min-width: 400px;
    height: 80vh;
  }
  .about-side__pic {
    height: 65%;
    width: 80%;
  }
  .about-text {
    margin-right: 2rem;
  }
}

@media screen and (max-width: 820px) {
  .about-page__container {
    min-height: 100%;
  }
  .about-side__pic__container {
    display: none;
  }
  .about-side__pic {
    display: none;
  }
  .about-text__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0px;
  }
  .about-text {
    margin-right: 2rem;
    margin-left: 2rem;
  }
} */

@media screen and (max-width: 820px) {
  .about-page__container {
    min-height: 100%;
  }

  .about-side__pic__container {
    display: none;
  }

  .about-text__container {
    display: flex;
    justify-content: none;
    margin: 2rem;
    margin-bottom: 0rem;
  }
}

@media screen and (max-width: 500px) {
  .about-text__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
