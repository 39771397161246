* {
  padding: 0;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/ProximaNova-Regular.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("fonts/ProximaNovaBold.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "ProximaNova";
  src: url("fonts/ProximaNovaExtrabold.woff2") format("woff2");
  font-weight: 800;
}

@font-face {
  font-family: "ProximaNova";
  src: url("fonts/ProximaNovaThin.woff2") format("woff2");
  font-weight: 200;
}

html {
  background: black;
  background-repeat: repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: contain;
  overflow-x: hidden;
  width: 100%;
  scroll-behavior: smooth;
  font-family: "ProximaNova";
}

/* @media screen and (max-width: 1440px) {
  html {
    font-size: 85%;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 75%;
  }
}

@media screen and (max-width: 820px) {
  html {
    font-size: 80%;
    max-width: 820px;
  }
}

@media screen and (max-width: 500px) {
  html {
    font-size: 70%;
    max-width: 500px;
  }
} */

/* @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  html {
    font-size: 80%;
  }
}

@media screen and (max-width: 500px) {
  html {
    font-size: 70%;
    max-width: 500px;
  }
} */

body {
  font-family: "ProximaNova";
}

a {
  text-decoration: none;
}
