.nav-background {
  display: none !important;
}

.nav-background-none {
  background: none !important;
}

.nav-background-none:hover {
  background: none !important;
  transition: 0.8s all ease;
}
