.landing-page__container {
  background-color: rgb(235, 233, 233);
  min-height: 100vh;
  margin-top: 0px;
}

.horizontally-centered {
  margin: 0 auto;
}

.content-padder {
  margin: auto;
  width: 85%;
}

.sep-grid {
  display: inline-grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  column-gap: 10px;
}
/* Move button bellow text */
@media screen and (max-width: 820px) {
  .content-padder {
    width: 90%;
  }

  .sep-grid {
    grid-template-columns: auto;
    row-gap: 10px;
    justify-content: center;
  }
}

.sep-grid div,
.sep-grid a {
  margin: auto;
}

@media screen and (max-width: 500px) {
  .content-padder {
    width: 95%;
  }
}

/* Make scrollable */
.scrollable {
  height: 100vh;
  overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
