#settings-block {
    position: relative;
    width: 100%;
}

#settings-container {

}

#settings-controls {
    padding-top: 20px;
}

.setting-container {
    /* width: 100%;
    padding-bottom: 10px; */
}

.setting-container input {
    width: 100%;
}


.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
}

.form-group {
    margin-bottom: 1rem;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.saved-settings {
    color: orangered;
    margin-bottom: 20px;
}