.eat-btn {
  color: rgb(255, 70, 0);
  background-color: rgba(255, 70, 0, 0.2);
  padding: 15px 20px;
  border-radius: 5px;
  position: relative;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: 300;
  -moz-transition: 300;
  -ms-transition: 300;
  -o-transition: 300;
}

.eat-btn:active {
  color: rgb(255, 70, 0);
  background-color: rgba(255, 70, 0, 0.4);
}

.eat-btn.deactivated {
  color: rgb(255, 70, 20, 0.5);
  background-color: rgba(255, 70, 20, 0.1);
}

@media screen and (max-width: 500px) {
  .eat-btn {
    padding: 10px 15px;
  }
}
