.resource {
  display: flex;
  flex-direction: column;
  min-height: auto;
  font-size: 1rem;
  line-height: 1.3rem;
}

/* .resource-button {
    align-self: baseline;
} */

.resource-button__grid {
  width: 100%;
  display: flex;
  gap: 2%;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.resource-button:hover {
  background-color: rgb(194, 194, 194);
  border-radius: 5px;
}

.resource-inner {
  padding-left: 1rem;
  padding-right: 1rem;

  /* position: relative; */

  display: grid;
  grid-template-rows: auto auto;
  align-content: center;
  gap: 1rem;
}

.resource h3 {
  margin-bottom: 7px;
  padding: 7px 0;
}

.resources-container {
  display: inline-grid;
  grid-template-columns: auto auto;
  gap: 1rem;
}

@media screen and (min-width: 1440px) {
  .resources_container {
    grid-template-columns: auto auto auto;
  }
}

@media screen and (max-width: 1200px) {
  .resources-container {
    display: inline-grid;
    grid-template-columns: auto;
    gap: 0.5rem;
  }
  .resource {
    width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .resources-container {
    display: flex;
    flex-direction: column;
  }

  .resource {
    width: 100%;
  }

  .resource p {
    font-size: 15px;
  }

  .white-block:hover {
    transform: none;
  }
}

@media screen and (max-width: 500px) {
  .resource {
    width: 100%;
  }

  .resource p {
    font-size: 14px;
  }
}
