.horizontally-centered {
  margin: 0 auto;
}

.dashboard-page__container {
  min-height: 110%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.landing-page-content__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-page__welcome {
  margin: 80px 0 20px;
  font-size: 2rem;
  color: rgb(105, 105, 105);
  border-bottom: 5px solid lightgray;
}

.landing-page__name {
  color: orangered;
}

.white-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 20px 20px;
  height: 100%;
  transition: transform 0.2s;
  font-size: 1rem;
}

.white-block-no-zoom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 40px 20px;
  height: 100%;
  transition: 0.2 ease-in-out;
}

.box-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  gap: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.hover-underline-animation {
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: orangered;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media screen and (max-width: 820px) {
  .landing-page__welcome {
    margin-top: 60px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 500px) {
  .landing-page__welcome {
    margin-top: 40px;
    font-size: 1.7rem;
    margin-bottom: 10px;
  }
  .white-block-new {
    padding: 40px 40px;
    min-width: 300px;
  }

  .white-block {
    padding: 20px 5px;
  }

  .box-text {
    gap: 1rem;
    font-size: 16px;
  }
}
