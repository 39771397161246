.check-inbox {
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  padding: 20px;
  width: 50%;
  background-color: black;
  border-radius: 10px;
  line-height: 2rem;
}

.mail-icon {
  margin-left: 20px;
}
