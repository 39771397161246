.top-btn {
  position: fixed;
  bottom: 65px;
  right: 20px;
  height: 40px;
  width: 40px;
  border: 2px solid orangered;
  color: white;
  background-color: orangered;
  cursor: pointer;
  z-index: 999;
  border-radius: 2px;
}

.bot-btn {
  position: fixed;
  bottom: 15px;
  right: 20px;
  height: 40px;
  width: 40px;
  border: 2px solid orangered;
  color: white;
  background-color: orangered;
  cursor: pointer;
  z-index: 999;
  border-radius: 2px;
}
