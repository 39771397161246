@media screen and (max-width: 820px) {
  .sidebar {
    display: none;
  }
}

.eatlogotxt {
  height: 80px;
  margin-top: 10px;
}

.sidebar {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-image: linear-gradient(108deg, rgb(255, 81, 0), #f7832b);
  float: left;

  &__logo {
    margin-top: 10px;
    display: grid;
    place-items: center;
    height: 100px;
    font-size: 1.5rem;
    font-weight: 900;
    color: white;
    border-bottom: 2px orangered;
  }

  &__menu {
    margin-top: 35px;
    position: relative;

    &__item {
      display: flex;
      align-items: center;
      place-content: flex-start;
      width: 10px;
      padding: 0.8rem 2rem;
      font-size: 1.1rem;
      font-weight: 500;
      transition: color 0.2s ease-in-out;

      &:focus {
        color: orangered;
        background-color: orangered;
      }

      &__icon {
        margin-right: 1rem;

        i {
          font-size: 1.75rem;
        }
      }
    }
  }
}
