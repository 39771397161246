.abouttwo-page__container {
  min-height: 100vh;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.abouttwo-side__pic__container {
  /* flex: 2 1 calc(100vw + 1rem); */
  /* flex: 1 1 calc(1000px + (2000 - 1000) * ((100vw - 1000px) / (1600 - 1000))); */
  margin-left: 1vw;
  margin-right: 4vw;

  padding: 10px;
  min-width: calc(350px + (430 - 350) * ((100vw - 1000px) / (1800 - 1000)));

  display: flex;
  align-items: center;
  justify-content: center;
}

.abouttwo-side__pic {
  max-width: 100%;

  border-radius: 30px;
}

.abouttwo {
  font-family: ProximaNova;
  font-size: 1.4rem;
  display: inline-grid;
  color: white;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.abouttwo-text__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(202, 199, 199);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 4.5vw;
  margin-right: 2vw;
  line-height: calc(23px + (35 - 23) * ((100vw - 320px) / (1600 - 320)));

  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1600 - 320)));
}

.about-header {
  font-family: ProximaNova;

  font-size: 2rem;
  font-weight: 900;
  color: black;
  padding: 20px;
  background-color: rgb(230, 230, 230);
  text-align: center;
}

.abouttwo-text__header {
  font-size: calc(26px + (32 - 26) * ((100vw - 320px) / (1600 - 320)));
  line-height: calc(32px + (40 - 32) * ((100vw - 320px) / (1600 - 320)));
  margin-bottom: 15px;
}

.abouttwo-text {
  font-size: 1.45rem;
  line-height: 2.2rem;
  margin-left: 6rem;
  padding: 10px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.above {
  text-decoration: underline;
}

/* @media screen and (max-width: 1440px) {
  .abouttwo-side__pic__container {
    padding: 20px;
    margin: 2rem;
  }

  .abouttwo-side__pic {
    max-width: 100%;
    height: auto;
    width: auto;
    padding: 20px;
    margin: 2rem;
  }

  .abouttwo-text {
    margin-right: 0;
    margin-left: 5rem;
    padding: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .abouttwo-side__pic {
    max-width: 100%;
    height: auto;
    width: auto;
    padding: 20px;
    margin: 2rem;
  }
}

@media screen and (max-width: 820px) {
  .abouttwo-side__pic__container {
    display: none;
  }

  .abouttwo-side__pic {
    display: none;
  }
  .abouttwo-text__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: none;
    padding-top: 0px;
  }

  .abouttwo-text {
    margin-right: 2rem;
    margin-left: 2rem;
    padding-top: 0px;
  }
}
} */

@media screen and (max-width: 820px) {
  .abouttwo-page__container {
    min-height: 100%;
  }

  .abouttwo-side__pic__container {
    display: none;
  }

  .abouttwo-text__container {
    display: flex;
    justify-content: none;
    margin: 0 2rem;
  }
}

@media screen and (max-width: 500px) {
  .abouttwo-text__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: none;
    margin: 0px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .abouttwo-side__pic__container {
    display: none;
  }
}
