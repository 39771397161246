.main-slide__container {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  min-height: 100vh;
  transition: 0.2s ease-in-out;
}

.fivehundred {
  display: inline-block;
  position: relative;
  color: orangered;
  font-weight: 800;
  min-width: 115px;
}

@property --num {
  syntax: "<integer>";
  initial-value: 500;
  inherits: false;
}

.fivehundred {
  animation: counter 3s 1 alternate ease-in-out;
  -webkit-animation: counter 3s 1 alternate ease-in-out;
  -moz-animation: counter 3s 1 alternate ease-in-out;
  animation-delay: 0.1s;
  counter-reset: num var(--num);
}
.fivehundred::after {
  content: counter(num);
}

.count-up__fivehundred {
  font-weight: 800;

  position: relative;
}

.count-up__container {
  display: inline-block;
  min-width: 140px;
  color: orangered;
}

@keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: 500;
  }
}

@media screen and (max-width: 1440px) {
  .count-up__container {
    min-width: 130px;
  }
}

@media screen and (max-width: 820px) {
  .main-slide__br {
    display: none;
  }

  .side-pic__container {
    display: none;
  }

  .heroh1-wrap {
    white-space: pre;
  }

  .main-slide__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .main-slide {
    margin: 0px 0px 200px 0px;
  }

  .apply-learn-button__container {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .header-container {
    min-width: 200px;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    margin-right: 0px;
    padding: 0px 40px 20px;
    max-width: none;
  }

  .second-header {
    margin-bottom: 10px;
  }

  .count-up__container {
    min-width: 120px;
  }
}

@media screen and (max-width: 500px) {
  .count-up__container {
    min-width: 100px;
  }
}

@media screen and (max-width: 320px) {
  .heroh1-wrap {
    white-space: pre-wrap;
  }
}
