.verify-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.verify-container {
  width: 100%;
}

.verify-button {
  width: 100%;
}

.verify-button:hover {
}

.soft-text {
  margin-top: 15px;
  color: white;
  font-weight: bold;
}

.verify-logout {
  margin-top: 20px;
  text-align: center;
  color: lightgray;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: black;
}

.verify-logout:hover {
  color: orangered;
}

.error-text {
  color: red;
}
