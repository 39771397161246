.notfound-container {
  min-height: 100vh;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.notfound-text {
  margin-top: -80px;
  color: white;
  width: 50%;
  line-height: 3rem;
}

.notfound-link {
  text-decoration: underline;
  color: orangered;
}

.oops {
  color: orangered;
}
