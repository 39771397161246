.footer-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: rgb(20, 19, 17);
  color: white;
}

.footer-contact {
  font-size: 0.75rem;
  margin-top: 4px;
  color: rgb(160, 157, 157);
}

.footer-contact__email {
  text-decoration: underline;
}

.footer-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  list-style: none;
  gap: 0.8rem;
}

.copyright {
  color: rgb(160, 157, 157);
  margin-top: 5px;
  font-size: 0.75rem;
}

.privacy-policy {
  text-decoration: underline;
}

a {
  color: inherit;
}
