.spinnercontainer {
  min-height: 100vh;
  background: inherit;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.spinner {
  margin-top: -100px;
  display: inline-block;
  width: 80px;
  height: 80px;
}
.spinner:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid orangered;
  border-color: orangered transparent orangered transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
