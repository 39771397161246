.alerts-container {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

.dashboard-alert {
  position: relative;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.danger-alert {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.danger-alert a {
  color: #6a1a21;
}

.primary-alert {
  display: flex;
  align-items: center;
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}
.primary-alert a {
  color: #06357a;
}

.alert-circle {
  margin-right: 20px;
}

.payment-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
}

.payment-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #062908;
  background-color: #a4df82;
  border-color: #a7e683;
}

.payment-date {
  text-decoration: underline;
}

.upcoming-header {
  white-space: nowrap;
}

.upcoming-text {
  margin-top: 20px;
  line-height: 1.2rem;
}

.todo-list {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  margin-left: 5px;
  gap: 5px;
  list-style-type: circle !important;
  white-space: nowrap;
}

.todo-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}

.upcoming-container {
  display: flex;
  flex-direction: column;
  background: #85bb65;
  max-height: 300px;
  flex: 1 1 60%;
}

.ftx-container {
  display: flex;
  flex-direction: column;
  background: #00a7c3;
  transition: transform 0.2s;
  max-height: 300px;
}

.ftx-text {
  margin-top: 12px;
  line-height: 1.2rem;
}

.ftx-logo {
  height: 28px;
}

.ftx-link {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 15px;
  margin-right: 25px;
  padding: 15px 20px;
  background: #87ebf4;
  text-decoration: none !important;
  border-radius: 5px;
}

.ftx-link:hover {
  background: white;
}

.dashboard-icon {
  margin-left: 10px;
}

@media screen and (max-width: 1200px) {
  .ftx-link {
    position: relative;
    margin: 0;
    margin-top: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 1043px) {
  .payment-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
  }

  .upcoming-container {
    height: auto;
    width: 100%;
  }

  .upcoming-text {
    margin-top: 13px;
  }

  .todo-list {
    margin-top: 8px;
  }

  .ftx-logo {
    height: 25px;
  }

  .ftx-link {
    position: relative;
    text-align: center;
    margin-bottom: 0px;
    margin: auto;
    margin-top: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .dashboard-alert {
    margin-bottom: 0.5rem;
  }

  .payment-container {
    gap: 0rem;
  }

  .upcoming-container {
    padding: 1rem;
    font-size: 15px;
  }

  .ftx-container {
    padding: 1rem;
    font-size: 15px;
  }

  .ftx-link {
    padding: 10px 0px;
    margin-top: 10px;
  }

  .warning-alert {
    margin-top: 0px;
    font-size: 15px;
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 500px) {
  .upcoming-container {
    margin-bottom: 0px;
    padding: 1rem;
    font-size: 13px;
    margin-top: 0px;
  }

  .upcoming-text {
    white-space: wrap;
  }

  .upcoming-container:hover {
    transform: none;
  }

  .ftx-logo {
    height: 20px;
  }

  .upcoming-text {
    margin-top: 10px;
  }
  .ftx-text {
    margin-top: 7px;
    font-size: 13px !important;
  }
  .ftx-link {
    padding: 10px 0px;
    margin: auto;
    margin-top: 10px;
  }

  .todo-list {
    margin-top: 8px;
  }

  .payment-date {
    margin-left: 0px;
  }

  .payment-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .ftx-container {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .ftx-container:hover {
    transform: none;
  }

  .warning-alert {
    margin-top: 0px;
    font-size: 12px;
  }

  .dashboard-alert {
    margin-bottom: 0;
  }
}

.warning-alert {
  display: flex;
  align-items: center;
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
  margin-bottom: 5px;
}
.warning-alert a {
  color: #523e02;
}

.alerts-container {
  /* width: 75%; */
  width: 100%;
  margin: auto;
}

.success-alert {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.success-alert a {
  color: #0c4128;
}

.dashboard-alert a {
  font-weight: 700;
  text-decoration: underline;
}
