/* .todo-grid {
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-between;
  grid-row-gap: 6%;
  margin-top: 0px;
} */

.todo-grid {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  justify-content: center;
  margin-bottom: 5px;
}

.todo-btns {
  transition: transform 0.2s;
}

.todo-btns:hover {
  transform: scale(1.01);
}

@media screen and (max-width: 820px) {
  .todo-grid {
    margin-top: 5px;
    gap: 0.5rem;
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .todo-grid {
    margin-top: 10px;
    margin-bottom: 5px;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
